import React from 'react'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import Hero from '../components/hero'
import SEO from '../components/seo'
import VideoModal from '../components/video-modal'
import styled, { createGlobalStyle } from 'styled-components'
import AspectRatio from 'react-aspect-ratio'
// import Anime from 'react-anime'

const VideoModalWrapper = styled.section`
height:100%;
.my-12 {margin-top:0!important;margin-bottom:0!important;}

.o-wrapper {padding-left:0;padding-right:0;}
.o-wrapper > .mx-auto {max-width:100%!important}

section.video,
.o-wrapper,
.o-wrapper > .mx-auto,
.my-12 {height:100%;}
`


const GlobalStyle = createGlobalStyle`
  .arrow-move:hover svg  {
    transform: translateX(5px);
  }
`

const LogoItem = styled.div`
  @media (max-width: 768px) {
    img {
      max-height:32px;
    }
  }
`
const Arrow = styled.div`
  width:37px;
  height:28px;
  @media (max-width: 1024px) {
    width:30px;
    height:20px;
  }
  svg {
    height:100%;
    width:100%;
    object-fit:contain;
  }
`;



const SustainabilityPage = ({ data: { page } }) => {
    const { acf, yoast_meta } = page;
    const linkColours = ['green', 'tan', 'blue'];
    return (
        <Layout>

            <GlobalStyle />

            <SEO yoast={yoast_meta} />

            {/* Hero */}
            <section className="hidden md:block mb-32 mt-4">
                <div className="o-wrapper" style={{ maxWidth: '1329px' }}>
                    <div className="relative pb-20">
                        <AspectRatio ratio="1061/639" className="bg-tertiary rounded-sm hero-ratio-image" style={{ maxWidth: '75%' }}>
                            <div>
                                {acf.video_url &&
                                    <VideoModalWrapper>
                                        <VideoModal
                                            video={acf.video_url}
                                            embedType={acf.video_url.includes('youtu') ? 'iframe' : 'html5'}
                                            poster={acf.hero.hero.image}
                                        />
                                    </VideoModalWrapper>
                                }

                                {!acf.video_url &&
                                    <Image fluid={acf.hero.hero.image.localFile.childImageSharp.fluid} className="object-cover h-100 w-100" draggable={false} />
                                }

                            </div>
                        </AspectRatio>

                        <div className="absolute bottom-0 right-0 w-full h-full flex flex-col justify-end pointer-events-none">
                            <AspectRatio ratio="949/654" className={`bg-${acf.hero.hero.background_colour || 'green'} mr-0 ml-auto w-full h-full rounded-sm`} style={{ maxHeight: '82%', maxWidth: '980px', zIndex: '-1' }}></AspectRatio>
                        </div>

                        <div className="absolute bottom-0 right-0">
                            <div className="py-12 sm:px-10 px-6 bg-white inline-block md:mx-12 sm:mx-6 mx-4 mb-12 rounded-sm">
                                <div className="text-left" style={{ maxWidth: '26rem' }}>
                                    <h1 className="fade-in-up md:u-h1 u-h2 mb-6">{acf.hero.hero.heading}</h1>
                                    <p className="fade-in-up ani-delay-200">{acf.hero.hero.content}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="md:hidden">
                <Hero
                    heading={acf.hero.hero.heading}
                    content={acf.hero.hero.content}
                    image={acf.hero.hero.image.localFile.childImageSharp.fluid}
                    ogImage={acf.hero.hero.image}
                    video_url={acf.video_url}
                    buttons={acf.hero.hero.buttons}
                    bg={acf.hero.hero.background_colour || 'green'}
                />
            </div>


            {/* Intro */}
            <section className="md:my-40 my-20 lg:mt-32">
                <div className="o-wrapper">
                    <header className="grid md:grid-cols-2 row-gap-8 col-gap-2 mb-12">
                        <div>
                            <h2 className="u-h2 md:max-w-sm mx-auto">{acf.intro.heading}</h2>
                        </div>
                        <div>
                            <div className="post-content" dangerouslySetInnerHTML={{ __html: acf.intro.content }} />
                        </div>
                    </header>

                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-2">
                        {acf.intro.images.map(img =>
                            <div key={img}>
                                <img src={img} alt="" className="w-full h-full object-cover" />
                            </div>
                        )}
                    </div>
                </div>
            </section>

            {/* environment */}
            <section className="md:my-40 my-20 lg:mt-32">
                <div className="o-wrapper">
                    <header className="grid md:grid-cols-2 row-gap-8 col-gap-2 mb-12">
                        <div>
                            <h2 className="u-h2 md:max-w-sm mx-auto">{acf.environment.heading}</h2>
                        </div>
                        <div>
                            <div className="post-content" dangerouslySetInnerHTML={{ __html: acf.environment.content }} />
                        </div>
                    </header>

                    {/*<div className="grid sm:grid-cols-2 grid-cols-1 gap-2">*/}
                    {/*    {acf.environment.images.map(img =>*/}
                    {/*        <div key={img}>*/}
                    {/*            <img src={img} alt="" className="w-full h-full object-cover" />*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}

                    <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4 sm:mt-16 mt-10">
                        {acf.environment.stats.map(stat =>
                            <div key={stat.stat + stat.description} className="border-b-4 border-green bg-tertiary p-6">
                                <h4 className="lg:u-h2 md:u-h3 u-h2 mb-2">{stat.stat}</h4>
                                <p>{stat.description}</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            {/* impact */}
            <section className="md:my-40 md:my-20 my-16" id="speed">
                <div className="o-wrapper">
                    <div className="grid md:grid-cols-2 row-gap-8 gap-10 items-center">
                        <div>
                            <div className="md:max-w-md mr-auto">
                                <h2 className="u-h2 mb-6">{acf.impact.heading}</h2>
                                <div dangerouslySetInnerHTML={{ __html: acf.impact.content }} />
                            </div>
                        </div>
                        <div>
                            <Image fluid={acf.impact.image.localFile.childImageSharp.fluid} draggable={false} className="about-image-pad" />
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
}

export default SustainabilityPage

export const query = graphql`
  {
    page: wordpressPage(template: {eq: "sustainability.php"}) {
      id
      ...Yoast
      acf {
        hero {
          ... Hero
        }
        video_url

        intro {
          heading
          content
          images
        }

        environment {
          heading
          content
          #images
          stats {
            stat
            description
          }
        }

        impact {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:586) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
